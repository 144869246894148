import React from 'react'
import Scroll from './Scroll'
import { Waypoint } from 'react-waypoint'


class Nav extends React.Component {
    constructor() {
        super()
        this.state = {
            stickyNav: false,
        }
    }

    _handleWaypointEnter = () => {
        this.setState(() => ({ stickyNav: false }))
    }
    
    _handleWaypointLeave = () => {
        this.setState(() => ({ stickyNav: true }))
    }

    render() {
        return (
            <div>
                <Waypoint
                onEnter={this._handleWaypointEnter}
                onLeave={this._handleWaypointLeave}
                ></Waypoint>
                <nav id="nav" className={this.state.stickyNav ? 'alt' : ''}>
                    <ul>
                        <li>
                            <Scroll type="id" element="intro">
                                <a href="#">Introduction</a>
                            </Scroll>
                        </li>
                        <li>
                            <Scroll type="id" element="first">
                                <a href="#">First Section</a>
                            </Scroll>
                        </li>
                        <li>
                            <Scroll type="id" element="second">
                                <a href="#">Second Section</a>
                            </Scroll>
                        </li>
                        <li>
                            <Scroll type="id" element="cta">
                                <a href="#">Get Started</a>
                            </Scroll>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default Nav
