import React from 'react'
import { Link } from 'gatsby'
import content from '../../l10n/data'

import logo from '../../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <Link to="/">
            <span className="logo"><img src={logo} alt="" style={{width: '192px', height: '192px'}} /></span>
            <h1>Emoojle</h1>
        </Link>
        <p>
            {content.headerMessage}<br/>
            <small>
                {content.headerTagLine}
            </small>
        </p>
    </header>
)

export default Header
